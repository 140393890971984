import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import styles from './index.scss';

/**
 * Represents a single option group for a product's variants
 *
 * @todo this should only be imported into one bundle, not two
 */
class VariantOptions extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return ['data-selected-variant'];
  }

  readonly dataset!: {
    discountCode: string;
    /**
     * Boolean as a string. If set to true the "product-variant-option-changed" event will be
     * emitted from the VariantOptions custom element and the Window global.
     */
    emitFromSelf: 'false' | 'true';

    /**
     * Shopify product ID
     */
    productId: string;

    /**
     * Product title for the variant options
     */
    productTitle: string;

    /**
     * Shopify product type
     */
    productType: string;

    /**
     * URL for product detail page
     */
    productUrl: string;

    /**
     * The currently selected variant ID
     */
    selectedVariant: string;

    /**
     * For legacy value sets of product type "bundle", this attribute identifies the product ID for
     * the abstraction product created in Shopify by the Bundle App to represent the value-set.
     */
    valueSetParentId: string;
  };

  public shadowRoot!: ShadowRoot;
  private optionGroups: ProductVariantOptionGroup[] = [];
  private onOptionGroupChangedBound = this.onOptionGroupChanged.bind(this);

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    // We shouldn't use addRetroEventListener here because it causes a problem with the variant
    // selector modal. If we use addRetroEventListener all events are stored in the queue and after
    // opening the modal again all those events are fired. In this case removeEventListener doesn't
    // work as expected. It seems like we shouldn't use addRetroEventListener for components that
    // could be removed from the page.
    addEventListener('product-variant-option-group-changed', this.onOptionGroupChangedBound);
    this.ingestOptionGroups();
  }

  public disconnectedCallback() {
    removeEventListener('product-variant-option-group-changed', this.onOptionGroupChangedBound);
  }

  private onOptionGroupChanged(event: WindowEventMap['product-variant-option-group-changed']) {
    if (this.optionGroups.length === 0) {
      this.ingestOptionGroups();
    }

    if (parseInt(this.dataset.productId, 10) !== event.detail.id) {
      return;
    }

    let queryString = 'variant-element';

    let position = 1;
    for (const optionGroup of this.optionGroups) {
      if (optionGroup.name === event.detail.option_group) {
        optionGroup.selected_option = event.detail.selected_option;
      }

      if (position === 1) {
        queryString = queryString + `[data-option1="${optionGroup.selected_option}"]`;
      }

      if (position === 2) {
        queryString = queryString + `[data-option2="${optionGroup.selected_option}"]`;
      }

      if (position === 3) {
        queryString = queryString + `[data-option3="${optionGroup.selected_option}"]`;
      }

      position++;
    }

    const selectedVariant = this.querySelector<VariantElement>(queryString);
    if (!selectedVariant) {
      return;
    }

    this.dataset.selectedVariant = selectedVariant.dataset.id;

    // TODO: if emitFromSelf is true, we emit two events. why would we ever want to do this?

    type Detail = WindowEventMap['product-variant-option-changed']['detail'];
    const changeEvent = new CustomEvent<Detail>('product-variant-option-changed', {
      detail: {
        id: parseInt(this.dataset.productId, 10),
        option_groups: this.optionGroups,
        selected_variant_id: parseInt(this.dataset.selectedVariant, 10),
        title: this.dataset.productTitle,
        type: this.dataset.productType,
        url: this.dataset.productUrl,
        value_set_parent_id: parseInt(this.dataset.valueSetParentId, 10),
        metafields_discount_code: this.dataset.discountCode
      }
    });
    dispatchEvent(changeEvent);

    if (this.dataset.emitFromSelf === 'true') {
      this.dispatchEvent(changeEvent);
    }
  }

  private parseUniqueValuesAtPosition(position: number) {
    const variants = this.querySelectorAll<VariantElement>('variant-element');
    const uniques: string[] = [];
    for (const variant of variants) {
      let optionValue: string;
      if (position === 1) {
        optionValue = variant.dataset.option1;
      }

      if (position === 2) {
        optionValue = variant.dataset.option2;
      }

      if (position === 3) {
        optionValue = variant.dataset.option3;
      }

      if (optionValue && !uniques.includes(optionValue)) {
        uniques.push(optionValue);
      }
    }

    return uniques;
  }

  private getVariantsForOptionValueAndPosition(value: string, position: number) {
    let positionWord: string;
    if (position === 1) {
      positionWord = 'one';
    } else if (position === 2) {
      positionWord = 'two';
    } else if (position === 3) {
      positionWord = 'three';
    }

    if (!positionWord) {
      return;
    }

    const optionVariants = [];
    const variants = this.querySelectorAll<VariantElement>(
      `variant-element[data-option-${positionWord}="${value}"]`);
    for (const variant of variants) {
      optionVariants.push({
        available: variant.dataset.available === 'true',
        compareAtPrice: parseInt(variant.dataset.compareAtPrice, 10) || 0,
        id: parseInt(variant.dataset.id, 10),
        price: parseInt(variant.dataset.price, 10),
        sku: variant.dataset.sku,
        title: variant.dataset.title
      });
    }

    return optionVariants;
  }

  private ingestOptionGroupAtPosition(position: number, name: string, selected: string) {
    const options = [];
    const groupOptions = this.parseUniqueValuesAtPosition(position);
    for (const groupOption of groupOptions) {
      options.push({
        value: groupOption,
        variants: this.getVariantsForOptionValueAndPosition(groupOption, position)
      });
    }

    return {
      name,
      selected_option: selected,
      position,
      options
    };
  }

  private ingestOptionGroups() {
    const groups = this.querySelectorAll('variant-option-group');
    this.optionGroups = [];
    for (const group of groups) {
      const position = parseInt(group.dataset.position, 10);
      const newOptionGroup = this.ingestOptionGroupAtPosition(position, group.dataset.title,
        group.dataset.selected);
      this.optionGroups.push(newOptionGroup);
    }
  }
}

type VariantElement = HTMLElementTagNameMap['variant-element'];

interface ProductVariantOptionGroup {
  name: string;
  options: ProductVariantOption[];
  position?: number;
  selected_option: string;
}

interface ProductVariantOption {
  value: string;
  variants: Partial<{
    /**
     * Whether the variant has inventory in stock and is available for sale.
     */
    available: boolean;

    backorder_message: string;

    compare_at_price: number;

    customAttributes: {
      /**
       * Key or name of the attribute.
       */
      key: string;

      /**
       * Value of the attribute.
       */
      value: string;
    }[];

    hideBackorderInCollection: string;

    /**
     * Variant id
     */
    id: number;

    price: number;

    sku: string;

    title: string;
  }>[];
}

declare global {
  interface HTMLElementTagNameMap {
    'variant-options': VariantOptions;
  }
}

if (!customElements.get('variant-options')) {
  customElements.define('variant-options', VariantOptions);
}
