import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import styles from './index.scss';

/**
 * Represents a single option group for a product's variants.
 *
 * This is closely modeled after Shopify's liquid object `product_option`.
 *
 * A product option, such as size or color.
 *
 * @todo inline property selected, the way it is updated now is very strange, we cannot switch over
 * to just using the data attribute just yet because setting it would cause a duplicate render
 * because of the poor way that onOptionChanged is coded
 *
 * @see https://shopify.dev/docs/api/liquid/objects/product_option
 */
class VariantOptionGroup extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return ['data-selected'];
  }

  readonly dataset!: {
    /**
     * The product handle to redirect to if the user clicks the color option provided in the
     * colorAltOption dataset value.
     */
    colorAltHandle: string;

    /**
     * Defines the color options which should trigger a redirect based on the passed in
     * colorAltHandle value.
     */
    colorAltOption: string;

    /**
     * The 1-based index of the product option in the product.options_with_values array.
     *
     * This string contains an integer.
     *
     * @see https://shopify.dev/docs/api/liquid/objects/product_option
     */
    position: string;

    /**
     * Shopify product id, the id of the product to which this option group pertains.
     *
     * This string contains an integer.
     */
    productId: string;

    /**
     * The currently selected product option value. Comes from product_option.selected_value.
     *
     * If no value is currently selected, then nil is returned.
     *
     * Watch out when using this attribute. The initial value is set in an unsafe way in liquid.
     *
     * @todo we should only set this attribute if it is not nil in the relevant liquid files
     * @todo this should be renamed to "selectedValue" to match the liquid property name
     *
     * @see https://shopify.dev/docs/api/liquid/objects/product_option
     */
    selected: string;

    /**
     * Product option name
     *
     * @todo why did we call this title if the liquid property is named "name"? this is extra
     * confusion that is not needed, this should be renamed to name. no unnecessary indirection.
     *
     * @see https://shopify.dev/docs/api/liquid/objects/product_option
     */
    title: string;
  };

  private selected: string;
  private onOptionChangedBound = this.onOptionChanged.bind(this);

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    const inputs = this.querySelectorAll<HTMLInputElement>(
      'div[slot="variant-options"] input[type="radio"]');
    for (const input of inputs) {
      input.addEventListener('change', this.onOptionChangedBound);
    }
  }

  public disconnectedCallback() {
    const inputs = this.querySelectorAll<HTMLInputElement>(
      'div[slot="variant-options"] input[type="radio"]');
    for (const input of inputs) {
      input.removeEventListener('change', this.onOptionChangedBound);
    }
  }

  public attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    if (name === 'data-selected') {
      this.selected = newValue;
    }

    this.render();
  }

  private onOptionChanged(event: Event) {
    const target = <HTMLInputElement>event.target;
    this.selected = target.value;

    if (this.selected.toUpperCase() === this.dataset.colorAltOption?.toUpperCase()) {
      const urlParams = new URLSearchParams(location.search);
      const newUrl = new URL(`/products/${this.dataset.colorAltHandle}`, 'https://langehair.com');
      const offerOverride = urlParams.get('view');
      if (offerOverride === 'offer-1') {
        newUrl.searchParams.set('view', 'offer-1');
      }

      location.href = newUrl.toString();

      return;
    }

    // Update the aria-checked attribute
    const radioInputs = this.querySelectorAll<HTMLInputElement>('input[type="radio"]');
    for (const input of radioInputs) {
      if (input.checked) {
        input.setAttribute('aria-checked', 'true');
      } else {
        input.setAttribute('aria-checked', 'false');
      }
    }

    const id = parseInt(this.dataset.productId, 10);
    const position = parseInt(this.dataset.position, 10);

    type Detail = WindowEventMap['product-variant-option-group-changed']['detail'];
    const changeEvent = new CustomEvent<Detail>('product-variant-option-group-changed', {
      detail: {
        id,
        position,
        option_group: this.dataset.title,
        selected_option: this.selected
      }
    });
    dispatchEvent(changeEvent);

    this.render();
  }

  private render() {
    const titleEl = this.shadowRoot.querySelector('span.title');
    titleEl.textContent = this.dataset.title;

    const selectedEl = this.shadowRoot.querySelector('b.selected');
    selectedEl.textContent = this.selected;
  }
}

/**
 * @todo describe what this event represents and when it is fired
 */
type ProductVariantOptionGroupChangedEvent = CustomEvent<{
  id: number;
  option_group: string;
  position: number;
  selected_option: string;
}>;

declare global {
  interface WindowEventMap {
    'product-variant-option-group-changed': ProductVariantOptionGroupChangedEvent;
  }

  interface HTMLElementTagNameMap {
    'variant-option-group': VariantOptionGroup;
  }
}

if (!customElements.get('variant-option-group')) {
  customElements.define('variant-option-group', VariantOptionGroup);
}
